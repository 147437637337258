import { Platform, PlatformInfo } from '@/models/platform.model'
import PlatformService from '@/core/services/platform.service'
import { LoginDTO } from '@/modules/auth/auth.service'

import packageJson from '~/package.json'

export const preparePlatformInfo = (info: Partial<PlatformInfo>) => {
  const productUid = info.product?.uid
  const productModel = info.product?.model

  const device = {
    uid: PlatformService.platform === Platform.LOCAL ? 'SANDBOX' : productUid || 'UNKNOWN',
    model: PlatformService.platform === Platform.LOCAL ? 'SANDBOX' : productModel || 'UNKNOWN',
  }

  const app = {
    type: 'ITV_3',
  } as const
  const version = info.app?.version

  const application = { ...app, version: packageJson.version || version || '0.0.1' }

  const systemVersion = info.system?.version
  const platform = {
    type:
      PlatformService.platform === 'WEB_OS'
        ? ('WEB_OS' as const)
        : PlatformService.platform === 'TIZEN'
        ? ('TIZEN' as const)
        : PlatformService.platform === 'LOCAL'
        ? ('SANDBOX' as const)
        : ('UNKNOWN' as const),
    version: PlatformService.platform === Platform.LOCAL ? 'SANDBOX' : systemVersion || 'UNKNOWN',
  }

  const deviceApplicationInfo: Omit<LoginDTO, 'credentials'> = {
    device,
    application,
    platform,
  }

  return deviceApplicationInfo
}
