import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

// import Info from '@/modules/content/components/Info'
import Image from '@/components/Image'

import image4K from '@/assets/images/4K-80.webp'

import { ContentModel } from '@/models/content.model'

interface UhdInfoProps {
  card?: ContentModel
  className?: string
}

const UhdInfo = ({ card, className }: UhdInfoProps) => {
  const isUhd = card?.isUhd
  if (!isUhd) return null
  return (
    <div className={classNames(className, styles.UHDLabel)}>
      <Image src={image4K} alt={'4K'} />
    </div>
  )
  // return <Info infoText={'UHD'} className={className} />
}

export default UhdInfo
