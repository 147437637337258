import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import styles from '@/modules/films/views/Movie/styles.module.scss'

import FetchMoviesByGenreId from '@/modules/films/components/FetchMoviesByGenreId'
import SerialsByGenreIdSlider from '@/modules/serials/component/SerialsByGenreIdSlider'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { useGetContentByIdQuery } from '@/modules/content/content.api'
import { setChosenCard } from '@/modules/content/content.actions'
import { setEpisode, setSeason } from '@/modules/serials/serials.actions'

import { ContentModel, ContentType } from '@/models/content.model'
import { ContentPaths, Paths } from '@/core/router/router.paths'

import { SerialModel } from '@/models/serial.model'
import { useLazyGetOneContentMediaViewQuery } from '@/modules/syncMediaView/syncMediaView.api'

export const useChosenContent = <T extends ContentModel>(type: 'serial' | 'movie') => {
  const dispatch = useAppDispatch()

  const [getContentMediaView] = useLazyGetOneContentMediaViewQuery()

  const navigate = useNavigate()
  const { id } = useParams()

  const card = useAppSelector((state) => state.content.chosenCard) as T

  const {
    data: content,
    isLoading,
    isFetching,
  } = useGetContentByIdQuery({ contentId: id || '' }, { skip: !id })

  const loading = useMemo(() => {
    return isFetching || isLoading
  }, [isFetching, isLoading])

  useEffect(() => {
    if (!content) return
    dispatch(setChosenCard(content))
    if (type === 'movie') {
      setIsContentMediaViewResolved(true)
    }
  }, [content])

  const { 0: isContentMediaViewResolved, 1: setIsContentMediaViewResolved } = useState(false)

  useEffect(() => {
    if (!content || type !== 'serial') return

    const serial = content as SerialModel
    getContentMediaView({ contentId: serial.id })
      .unwrap()
      .then((info) => {
        if (info) {
          const episodeId = info.resourceId
          const episodeNumber = info.resource?.episode
          const seasonId = info.resource?.seasonId
          const seasonNumber = info.resource?.season

          const season = serial.serial?.seasons?.find(
            (s) => s.id === seasonId || s.number === seasonNumber,
          )
          if (!season) return
          dispatch(setSeason(season))

          const episode = season.episodes?.find((e) => {
            return (
              e.id === episodeId ||
              (episodeNumber !== null && episodeNumber !== undefined && e.number === episodeNumber)
            )
          })
          if (!episode) return
          dispatch(setEpisode(episode))
          return
        }

        const firstSeason = serial.serial?.seasons?.[0]
        if (!firstSeason) return
        dispatch(setSeason(firstSeason))
        const firstEpisode = firstSeason?.episodes?.[0]
        firstEpisode && dispatch(setEpisode(firstEpisode))
      })
      .catch(() => {
        const firstSeason = serial.serial?.seasons?.[0]
        if (!firstSeason) return
        dispatch(setSeason(firstSeason))
        const firstEpisode = firstSeason?.episodes?.[0]
        firstEpisode && dispatch(setEpisode(firstEpisode))
      })
      .finally(() => {
        setIsContentMediaViewResolved(true)
      })
  }, [content])

  const handleCardPress = useCallback(
    (item: ContentModel) => {
      if (item.id === card?.id || loading) return
      dispatch(setChosenCard(item))
      navigate(
        `${Paths.CONTENT}/${
          item.type === ContentType.MOVIE ? ContentPaths.MOVIES : ContentPaths.SERIALS
        }/${item.id}`,
      )
    },
    [card],
  )

  const sliders = useMemo(() => {
    if (!card || !card.genres) return []

    if (type === 'serial') {
      return card.genres.map((el, index) => {
        return (
          <SerialsByGenreIdSlider
            key={index + 1}
            genreId={el.id}
            rowProps={{ title: el.pluralTitle }}
            height={447}
            onCardPress={handleCardPress}
          />
        )
      })
    }

    return card.genres.map((el, index) => {
      return (
        <FetchMoviesByGenreId
          key={index + 1}
          id={el.id}
          rowProps={{
            title: el.pluralTitle,
            className: styles.ScrollingWrapper,
            titleClassName: styles.GenreTitle,
          }}
          // onCardFocus={handleCardFocus}
          onCardPress={handleCardPress}
          height={447}
        />
      )
    })
  }, [card, content, type])

  return useMemo(() => {
    return {
      loading,
      sliders,
      shouldShowSkeleton: !card || !card.genres || !isContentMediaViewResolved,
    }
  }, [loading, card, sliders, isContentMediaViewResolved])
}
