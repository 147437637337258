import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import DefaultCard, { DefaultCardProps } from '@/components/DefaultCard'
import ContentCardInner from '@/modules/content/components/ContentCard/ContentCardInner'

import { ContentModel } from '@/models/content.model'

export interface ContentCardProps<T> extends Omit<DefaultCardProps<T>, 'children'> {}

function ContentCard<T extends ContentModel>({
  card,
  className,
  wrapperClassName,
  onCardFocus,
  ...props
}: ContentCardProps<T>) {
  return (
    <DefaultCard<T>
      card={card}
      className={classNames(className, styles.ContentCardFocusWrapper)}
      wrapperClassName={classNames(wrapperClassName, styles.ContentCardWrapper)}
      focusClassName={styles.ContentCardFocusWrapper__Focused}
      onCardFocus={onCardFocus}
      {...props}
    >
      <ContentCardInner<T> card={card} />
    </DefaultCard>
  )
}

export default ContentCard
