export const samsungKeyCodes = {
  BUTTON_POWER: -2085,
  BUTTON_MUTE: 449,
  BUTTON_MIC: -1002,
  BUTTON_CLOCK: -2032,
  BUTTON_NUM1: 49,
  BUTTON_NUM2: 50,
  BUTTON_NUM3: 51,
  BUTTON_NUM4: 52,
  BUTTON_NUM5: 53,
  BUTTON_NUM6: 54,
  BUTTON_NUM7: 55,
  BUTTON_NUM8: 56,
  BUTTON_NUM9: 57,
  BUTTON_NUM0: 48,
  BUTTON_MENU: 262,
  BUTTON_BACK: 10009,
  BUTTON_SMART_HUB: 10071,
  BUTTON_CHANNEL_PREV: 428,
  BUTTON_CHANNEL_NEXT: 427,
  BUTTON_CHANNEL_LIST: 10073,
  BUTTON_EXIT: 10182,
  BUTTON_SEARCH: 10225,
  BUTTON_TOOLS: 10135,
  BUTTON_REFRESH: -116,
  BUTTON_UP: 38,
  BUTTON_DOWN: 40,
  BUTTON_LEFT: 37,
  BUTTON_RIGHT: 39,
  BUTTON_OK: 13,
  BUTTON_PAGE_NEXT: -34,
  BUTTON_PAGE_PREV: -33,
  BUTTON_NEXT: -34,
  BUTTON_PREV: -33,
  BUTTON_VOL_UP: 448,
  BUTTON_VOL_DOWN: 447,
  BUTTON_RED: 403,
  BUTTON_GREEN: 404,
  BUTTON_YELLOW: 405,
  BUTTON_BLUE: 406,
  BUTTON_SERVICES: -120,
  BUTTON_TV: -84,
  BUTTON_PHONE: -119,
  BUTTON_EPG: -119,
  BUTTON_WEB: 1 - 23,
  BUTTON_APP: -123,
  BUTTON_REW: 412,
  BUTTON_FFWD: 417,
  BUTTON_PLAY: 415,
  BUTTON_PAUSE: 19,
  BUTTON_CONTINUE: -2082,
  BUTTON_STOP: 413,
  BUTTON_REC: 416,
  BUTTON_INFO: 457,
  BUTTON_FRAME: -1031,
  BUTTON_AUDIO: -2071,
  BUTTON_NULL: -2076,
  BUTTON_KEYBOARD: 10148,
  BUTTON_USB_UNMOUNTED: -2081,
  BUTTON_USB_MOUNTED: -2080,
  BUTTON_SOURCE: 10072,
  BUTTON_VOICE: 10224,
  BUTTON_PLAY_PAUSE: 10252,
  BUTTON_EXTRA: 10253,
  BUTTON_SECOND_LAST_KEY: 10228,
  BUTTON_M_VIEW: 10236,
  BUTTON_LG_BACK: 461,
}

export const lgKeyCodes = {
  BUTTON_POWER: -2085,
  BUTTON_MUTE: 449,
  BUTTON_MIC: -1002,
  BUTTON_CLOCK: -2032,
  BUTTON_NUM1: 49,
  BUTTON_NUM2: 50,
  BUTTON_NUM3: 51,
  BUTTON_NUM4: 52,
  BUTTON_NUM5: 53,
  BUTTON_NUM6: 54,
  BUTTON_NUM7: 55,
  BUTTON_NUM8: 56,
  BUTTON_NUM9: 57,
  BUTTON_NUM0: 48,
  BUTTON_MENU: 262,
  BUTTON_BACK: 461,
  BUTTON_SMART_HUB: 10071,
  BUTTON_CHANNEL_PREV: 34,
  BUTTON_CHANNEL_NEXT: 33,
  BUTTON_CHANNEL_LIST: 10073,
  BUTTON_EXIT: 10182,
  BUTTON_SEARCH: 10225,
  BUTTON_TOOLS: 10135,
  BUTTON_REFRESH: -116,
  BUTTON_UP: 38,
  BUTTON_DOWN: 40,
  BUTTON_LEFT: 37,
  BUTTON_RIGHT: 39,
  BUTTON_OK: 13,
  BUTTON_PAGE_NEXT: -34,
  BUTTON_PAGE_PREV: -33,
  BUTTON_NEXT: -34,
  BUTTON_PREV: -33,
  BUTTON_VOL_UP: 448,
  BUTTON_VOL_DOWN: 447,
  BUTTON_RED: 403,
  BUTTON_GREEN: 404,
  BUTTON_YELLOW: 405,
  BUTTON_BLUE: 406,
  BUTTON_SERVICES: -120,
  BUTTON_TV: -84,
  BUTTON_PHONE: -119,
  BUTTON_EPG: -119,
  BUTTON_WEB: 1 - 23,
  BUTTON_APP: -123,
  BUTTON_REW: 412,
  BUTTON_FFWD: 417,
  BUTTON_PLAY: 415,
  BUTTON_PAUSE: 19,
  BUTTON_CONTINUE: -2082,
  BUTTON_STOP: 413,
  BUTTON_REC: 416,
  BUTTON_INFO: 457,
  BUTTON_FRAME: -1031,
  BUTTON_AUDIO: -2071,
  BUTTON_NULL: -2076,
  BUTTON_KEYBOARD: 10148,
  BUTTON_USB_UNMOUNTED: -2081,
  BUTTON_USB_MOUNTED: -2080,
  BUTTON_SOURCE: 10072,
  BUTTON_VOICE: 10224,
  BUTTON_PLAY_PAUSE: 10252,
  BUTTON_EXTRA: 10253,
  BUTTON_SECOND_LAST_KEY: 10228,
  BUTTON_M_VIEW: 10236,
}