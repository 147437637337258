import { PaginateArgs } from '@/core/api/api'

import { ContentRelationsKeys } from '@/models/content.model'
import { ChannelRelationsKeys } from '@/models/channel.model'
import { AppDispatch } from '@/core/store/store'
import { tvApi } from '@/modules/tv/tv.api'
import { contentApi } from '@/modules/content/content.api'
import { genreApi } from '@/modules/genre/genre.api'
import { getFromLocalStorage, removeKeyFromLocalStorage, saveToLocalStorage } from '@/core/utils'
// import { profileApi } from '@/modules/profile/profile.api'
import { paymentsApi } from '@/modules/payments/paymentsApi'
import { subscriptionApi } from '@/modules/subscriptions/subscriptions.api'
import { partnerApi } from '@/modules/partner/partner.api'
import { feedsApi } from '@/modules/feeds/feeds.api'
import { promotesApi } from '@/modules/promotes/promotes.api'
import { favoritesMediaApi } from '@/modules/favorites/favorites.media.api'
import { favoritesChannelsApi } from '@/modules/favorites/favorites.channels.api'
import dayjs from 'dayjs'

const prepareRelations = <T extends string>(
  allRelations: T[],
  relations: { [key: string]: boolean },
  excludeRelationsArr: T[] = [],
): string => {
  const filteredRelations = allRelations.filter((el) => {
    return excludeRelationsArr.indexOf(el) === -1
  })

  if (!(relations && Object.keys(relations).length > 0)) {
    return filteredRelations.join(',')
  }

  const finalRelations: T[] = []

  filteredRelations.forEach((relation) => {
    if (relations[relation] === undefined || relations[relation]) {
      finalRelations.push(relation)
    }
  })

  return finalRelations.join(',')
}

export const prepareContentRelations = (
  relations: { [key: string]: boolean },
  excludeRelationsArr: ContentRelationsKeys[] = [],
): string => {
  const allContentRelations: ContentRelationsKeys[] = [
    'age',
    'movie',
    'serial',
    'genres',
    'trailer',
    'partner',
    'countries',
    'actors',
    'posters.verticalImage,posters.horizontalImage',
    'backgrounds.image',
    'serial.seasons.episodes',
    // 'promo',
  ]

  return prepareRelations(allContentRelations, relations, excludeRelationsArr)
}

export const prepareChannelRelations = (
  relations: { [key: string]: boolean },
  excludeRelationsArr: ChannelRelationsKeys[] = [],
): string => {
  const allChannelRelations: ChannelRelationsKeys[] = ['logo', 'categories', 'channelsCategories']

  return prepareRelations(allChannelRelations, relations, excludeRelationsArr)
}

export const preparePagination = (paginateObj: Partial<PaginateArgs> = {}): string => {
  return `${paginateObj.page ? paginateObj.page : 1}:${paginateObj.limit ? paginateObj.limit : 10}`
}

export const resetStateAfterLogoutOrProfileChange = (dispatch: AppDispatch) => {
  dispatch(tvApi.util.resetApiState())
  // dispatch(profileApi.util.resetApiState())
  dispatch(paymentsApi.util.resetApiState())
  dispatch(subscriptionApi.util.resetApiState())
  dispatch(contentApi.util.resetApiState())
  dispatch(favoritesMediaApi.util.resetApiState())
  dispatch(favoritesChannelsApi.util.resetApiState())
  dispatch(genreApi.util.resetApiState())
  dispatch(partnerApi.util.resetApiState())
  dispatch(feedsApi.util.resetApiState())
  dispatch(promotesApi.util.resetApiState())
}

export const hasModalNodeOwnChild = (): boolean => {
  const modalNode = document.getElementById('modal')

  if (!modalNode) return false

  return modalNode.hasChildNodes()
}

export const hasModalNextSibling = (overlay?: HTMLDivElement | null): boolean => {
  if (!overlay) return false

  const parent = overlay.parentElement
  if (!parent) return false

  const next = parent.nextSibling

  return !!next
}

const saveRouterHistoryToLocalstorage = (history: string[]) => {
  saveToLocalStorage('navigation-history', history)
}

const getRouterHistoryFromLocalstorage = (): string[] | null => {
  return getFromLocalStorage<string[]>('navigation-history')
}

export const removeRouterHistoryInLocalstorage = () => {
  return removeKeyFromLocalStorage('navigation-history')
}

export const addPathToRouterHistoryToLocalstorage = (path: string) => {
  if (!path) return
  const history = getRouterHistoryFromLocalstorage()

  if (!history) {
    saveRouterHistoryToLocalstorage([path])
    return
  }

  if (history[history.length - 1] === path) return

  if (history.length < 10) {
    saveRouterHistoryToLocalstorage(history.concat(path))
    return
  }

  saveRouterHistoryToLocalstorage([history[0], ...history.slice(2), path])

  // return removed element
  return history[1]
}

export const getNavigationHistory = () => getFromLocalStorage<string[]>('navigation-history')

export const getPrevPathFromRouterHistoryFromLocalStorage = (): string | null => {
  const history = getNavigationHistory()
  if (!history) return null

  const last = history.pop()
  if (!last) return null

  const prev = history.pop()

  if (!prev) return null

  saveRouterHistoryToLocalstorage(history)

  return prev
}

export const shouldShowNewYearSkin = () => {
  const month = dayjs().month()
  const date = dayjs().date()

  if (![0, 11].includes(month)) return false

  if (month === 0 && date >= 15) return false

  return !(month === 11 && date < 15)
}
