import { lgKeyCodes, samsungKeyCodes } from '@/core/keys/keyCodes'

const ChannelDownLGKey = 'PageDown'
const ChannelDownTizenKey = 'XF86LowerChannel'
const LocalChannelDownKey = '-'

export const isChannelDown = (e?: KeyboardEvent) => {
  if (!e) return false
  if (!e.key) return false

  return e.key === ChannelDownLGKey
    || e.key === ChannelDownTizenKey
    || e.key === LocalChannelDownKey
    || e.keyCode === lgKeyCodes.BUTTON_CHANNEL_PREV
    || e.keyCode === samsungKeyCodes.BUTTON_CHANNEL_PREV
}
