import React from 'react'

import styles from './styles.module.scss'

import SpinnerImage from '@/assets/images/loaders/loader-0.svg'
import SnowFlake from '@/assets/images/loaders/loader-3.svg'

import { shouldShowNewYearSkin } from '@/core/helpers'

const Spinner = () => {
  return (
    <div className={styles.Spinner}>
      <img src={shouldShowNewYearSkin() ? SnowFlake : SpinnerImage} alt='Indicator' />
    </div>
  )
}

export default Spinner
