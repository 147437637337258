import { useGetAllChannelsQuery } from '@/modules/tv/tv.api'
import {
  favoritesChannelsAdapter,
  favoritesChannelsSelectors,
  useGetAllMeFavoritesChannelsQuery,
} from '@/modules/favorites/favorites.channels.api'
import { useMemo } from 'react'
import { sortCompareFunction } from '@/core/sort'
import { ChannelModel } from '@/models/channel.model'
import { useAppSelector } from '@/core/store'

export const useGetPreparedChannels = () => {
  const chosenCategoryId = useAppSelector((state) => state.tv.chosenCategory?.id)

  const { data, isLoading, isFetching } = useGetAllChannelsQuery({})
  const {
    data: favorites,
    isLoading: loadingFavorites,
    isFetching: fetchingFavorites,
  } = useGetAllMeFavoritesChannelsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => {
      return {
        data: favoritesChannelsSelectors.selectAll(
          data ?? favoritesChannelsAdapter.getInitialState(),
        ),
        ...rest,
      }
    },
  })

  const preparedChannels = useMemo(() => {
    if (!data) return
    // resetChannelsListPosition()
    if (!chosenCategoryId) return data

    if (chosenCategoryId === 'favorites') {
      return data.filter((channel) => {
        return !!favorites.find((favoriteChannel) => channel.id === favoriteChannel.id)
      })
    }

    return data
      .filter((channel) => {
        const channelCategories = channel.categories
        if (!channelCategories) return false

        return channelCategories.map((cat) => cat.id).includes(chosenCategoryId)
      })
      .map((channel) => ({
        ...channel,
        // if chosenCategoryId defined change channel order to specific order from channelsCategories table
        order:
          channel.channelsCategories?.find(
            (channelCategory) => channelCategory.categoryId === chosenCategoryId,
          )?.order ?? channel.order,
      }))
      .sort(
        // sort by new order
        sortCompareFunction<ChannelModel>({
          ascending: true,
          sortBy: 'order',
        }),
      )
  }, [chosenCategoryId, data, favorites])

  return useMemo(() => {
    return {
      loading: isLoading || loadingFavorites || isFetching || fetchingFavorites,
      preparedChannels,
    }
  }, [
    preparedChannels,
    isLoading,
    isFetching,
    loadingFavorites,
    fetchingFavorites])
}