import React, { FC, HTMLProps } from 'react'

import styles from './styles.module.scss'

import logo from '@/assets/images/logo.png'
import hat from '@/assets/images/santa-hat.webp'
import classNames from 'classnames'
import ImageLoader from '@/components/Image'
import { shouldShowNewYearSkin } from '@/core/helpers'

type DivProps = HTMLProps<HTMLDivElement>

interface LogoProps extends Omit<DivProps, 'className'> {
  className?: string
}

const Logo: FC<LogoProps> = ({ className, ...restProps }) => {
  return (
    <div className={classNames(styles.Logo, className && className)} {...restProps}>
      <ImageLoader src={logo} alt={'ITV'} skeleton roundedSkeleton />
      <SantasHat />
    </div>
  )
}

export const SantasHat = () => {
  if (!shouldShowNewYearSkin()) {
    return null
  }

  return (
    <div className={styles.Hat}>
      <ImageLoader src={hat} skeleton roundedSkeleton />
    </div>
  )
}

export default Logo
